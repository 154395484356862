<template>
  <v-container fluid>
    <v-card class="">
      <v-toolbar flat>
        <vue-excel-xlsx
          :data="items"
          :columns="headers.map((i)=>{return {label:i.text, field: i.value}})"
          :file-name="'منصة السراج-'+ (new Date()).toLocaleDateString()"
          :file-type="'xlsx'"
          :sheet-name="'sheet1'"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            {{ $t("export") }}
            <v-icon class="ms-2"> mdi-file-excel</v-icon>
          </v-btn>
        </vue-excel-xlsx>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items-per-page2="-1"
        :items="items"
        fixed-header
        class="bordered"
        :show-footer="false"
      >

      </data-table-component>
    </v-card>
  </v-container>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  components: {DataTableComponent},
  props: [
    "statisticsData"
  ],
  data() {
    return {};
  },
  computed: {
    items() {
      const items = this.statisticsData.map((item) => {
        for (const index in item.items) {
          const subItem = item.items[index]
          item[subItem.key] = subItem.value
        }
        return item
      })

      return items
    },
    headers() {
      const headers = [{
        text: this.$t("statistics.governorate"),
        value: "title",
        width: 150,
        class: ["fixed"],
        cellClass: ["fixed"]
      }]
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      headers.push(...this.statisticsData[0].items.map((item) => {
        return {name: item.key, text: item.label, value: item.key, width: 150, tip: item.tip}
      }))
      return headers
    }
  },
  created() {

  },

};
</script>

