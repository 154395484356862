<template>
  <v-container fluid>
    <v-card class="">
      <v-col>
        <v-row class="ma-10" v-for="item in statisticsData" :key="item.key">
          <material-card class="my-2" color="primary darken-2" >
            <template #heading>
              <div class="white--text">
                <div class="font-weight-light">
                  {{ item.title }}
                </div>
              </div>
            </template>
            <v-row class="align-baseline">
              <v-col cols="12" sm="4" md="3" xl="2" class="align-self-start" v-for="subItem in item.items"
                     :key="subItem.key">
                <v-text-field style="font-size: 1.5rem" :label="subItem.label" :value="subItem.value || 0 " readonly outlined background-color="white">
                  <template #append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <v-icon   v-bind="attrs"
                                  v-on="on">mdi-information-outline</v-icon>

                      </template>
                      <span>{{subItem.tip}}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </material-card>
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>
<script>
import MaterialCard from "../../components/base/MaterialCard.vue";

export default {
  components: {
    MaterialCard,
  },
  props: [
    "statisticsData"
  ],
  data() {
    return {};
  },
  computed: {

  },
  created() {

  },

};
</script>
