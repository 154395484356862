<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <div class="text-center ma-12" v-if="loading">
        <v-progress-circular size="64" indeterminate color="primary" width="6"/>
      </div>
      <div v-else>
        <v-tabs v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
        >
          <v-tab :key="1">
            {{ $t("statistics.simple") }}
          </v-tab>
          <v-tab :key="2">
            {{ $t("statistics.detailed") }}
          </v-tab>
          <v-tab :key="3">
            {{ $t("statistics.cards") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="1"
          >
            <Simple :statistics-data="statisticsData"></Simple>
          </v-tab-item>
          <v-tab-item
            :key="2"
          >
            <Detailed :statistics-data="statisticsData"/>
          </v-tab-item>
          <v-tab-item
            :key="3"
          >
            <statistics-cards :statistics-data="statisticsData"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import Simple from "./Simple.vue";
import Detailed from "./Detailed.vue";
import StatisticsCards from "./StatisticsCards.vue";

export default {
  components: {StatisticsCards, Detailed, Simple},
  data() {
    return {
      loading: true,
      statisticsData: [],
      tab: 0
    };
  },
  computed: {
    headers() {
      var list = [
        {text: this.$t("servers.domain"), value: "domain"},
        {text: this.$t("servers.ip"), value: "ip"},
        {text: this.$t("servers.max_schools"), value: "max_schools"},
        {
          text: this.$t("servers.allow_schools"), value: "allow_schools",
          map: (value) => value ? 'مسموح' : 'غير مسموح'
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      actions.templates.push({
        type: "btn",
        icon: "mdi-pencil",
        text: this.$t("edit"),
        click: this.editItem,
      });
      actions.templates.push({
        type: "btn",
        icon: "mdi-delete",
        text: this.$t("delete"),
        click: this.deleteItem,
        color: "red darken-2",
      });
      list.push(actions);
      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(107)) {
      this.$router.push({name: "notauthorize"});
    }

    this.loading = true;
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.$axios
          .get("statistics", {noToast: true, failureToast: true})
          .then((response) => {
            this.statisticsData = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
